import React, { useState } from "react";
import {
  TextField,
  Button,
  Grid,
  Paper,
  Typography,
  Box,
  InputAdornment,
  IconButton,
  Snackbar,
  Alert,
  Card,
  CardActionArea,
  CardContent,
  Tabs,
  Tab,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { apiList, invokeApi } from "../services/apiServices";
import { config } from "../config/config";
import backgroundimage from "../assets/itteamphoto.jpg";
import { Email, Lock } from "@mui/icons-material";
import logo from "../assets/SN (1).png";
import getmycollege from "../assets/getmycollegeicon.png";
import getmyproperty from "../assets/getmypropertyicon-removebg-preview.png";
import Podarlogo from "../assets/podar-logo.png";
import akashlogo from "../assets/akash logo.png";
import SendIcon from "@mui/icons-material/Send";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [cookies, setCookie] = useCookies();
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [email, setEmail] = useState("");
  const [env, setEnv] = useState("getmycolleges");
  const navigate = useNavigate();

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenSnackbar(true);
  };

  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") return;
    setOpenSnackbar(false);
  };

  const handleLogin = async () => {
    setError(null);
    setLoading(true);

    const apiBaseURL =
      env === "getmycolleges"
        ? "https://api.getmycolleges.com" : env === "akash" ? "https://akash.sninfosystems.co.in"
          : env === "podar" ? "https://podar.sninfosystems.co.in" : "https://crm.amruthaeducation.com";

    const cookieName = env === "getmycolleges" ? "clg_user" : env === "akash" ? "akashcrm_user" : env === "podar" ? "podarcrm_user" : "prop_user";

    let params = {
      email: username,
      password: password,
    };

    try {
      const response = await invokeApi(
        apiBaseURL + apiList.userLogin,
        params,
        cookies
      );

      console.log("API Response:", response); // Log the response to verify the status and data.

      if (response?.status >= 200 && response?.status < 300) {
        if (response.data.responseCode === "200") {
          console.log("Login successful. Setting cookies and redirecting...");

          // Set the cookie for the current domain
          setCookie(
            cookieName,
            JSON.stringify({
              token: response.data.token,
              loginUserId: response.data.userId,
            }),
            {
              path: "/",
              maxAge: 3000000, // Cookie expiry time
              sameSite: "None", // Cross-domain cookie
              secure: true, // Only for HTTPS connections
            }
          );

          // // After login, set the cookies for the other domain (second domain)
          // if (env === "getmycolleges") {
          //   // Set cookies for amruthaeducation.com
          //   document.cookie = `prop_user=${JSON.stringify({
          //     token: response.data.token,
          //     loginUserId: response.data.userId,
          //   })}; path=/; domain=amruthaeducation.com; max-age=3000000; SameSite=None; Secure`;
          // } else {
          //   // Set cookies for getmycolleges.com
          //   document.cookie = `clg_user=${JSON.stringify({
          //     token: response.data.token,
          //     loginUserId: response.data.userId,
          //   })}; path=/; domain=getmycolleges.com; max-age=3000000; SameSite=None; Secure`;
          // }

          // Redirect to the respective dashboard with token and userId as query params
          const redirectURL =
            env === "getmycolleges"
              ? `http://www.getmycolleges.com?token=${response.data.token}&userId=${response.data.userId}`
              : env === "akash" ? `http://akash.sninfosystems.co.in?token=${response.data.token}&userId=${response.data.userId}`
                : env === "podar" ? `http://podar.sninfosystems.co.in?token=${response.data.token}&userId=${response.data.userId}`
                  : `https://www.amruthaeducation.com?token=${response.data.token}&userId=${response.data.userId}`;

          console.log("Redirecting to:", redirectURL); // Log the URL to ensure it's correct

          // Use window.location.replace to ensure the user can't go back to login
          window.location.replace(redirectURL); // Try this instead of href to force redirection
        } else if (response.data.responseCode === "HE001") {
          showSnackbar(
            "Invalid credentials. Please check your email and password.",
            "error"
          );
        } else {
          showSnackbar(
            "Something went wrong while logging in. Please try again later!",
            "error"
          );
        }
      } else {
        showSnackbar(
          "Something went wrong while logging in. Please try again later!",
          "error"
        );
      }
    } catch (error) {
      console.error("Error during login:", error);
      showSnackbar("Something went wrong. Please try again later!", "error");
    } finally {
      setLoading(false);
    }
  };

  const handleTabClick = (envValue) => {
    setEnv(envValue);
  };

  const announcements = [
    {
      title: "Effortless Lead Management",
      description: "“Track, assign, and update leads seamlessly in real time.",
    },
    {
      title: "Advanced Analytics Dashboard",
      description:
        "Gain actionable insights into lead performance and conversions.",
    },
    {
      title: "Integrated Communication Tools",
      description:
        "Connect with prospects via email, SMS, or calls directly from the CRM.",
    },
  ];

  return (
    <Grid
      container
      display={"flex"}
      sx={{
        position: "relative",
        height: "100vh",
        backgroundImage: `url(${backgroundimage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/* Overlay */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent black overlay
          zIndex: 1,
        }}
      />

      <Grid
        item
        xs={12} // Full width on mobile
        sm={8} // 8 out of 12 on small screens
        md={4}
        sx={{
          width: "100%",
          height: "500px",
          display: "flex",
          borderRadius: 5,
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#f5f5f5",
          position: "relative",
          zIndex: 2,
        }}
      >
        <Grid
          elevation={10}
          sx={{
            padding: 4,
            width: "400px",
            borderRadius: "16px",
          }}
        >
          <Box display="flex" justifyContent="center" mb={3}>
            <img
              src={logo}
              alt="Go Smart ERP Logo"
              style={{ width: "200px" }}
            />
          </Box>

          <TextField
            variant="outlined"
            fullWidth
            placeholder="Email"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            sx={{ marginBottom: 2 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Email />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            variant="outlined"
            fullWidth
            placeholder="Password"
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            sx={{ marginBottom: 2 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Lock />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleTogglePasswordVisibility}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          {/* Tab Section */}
          <Box>
            <Grid
              container
              spacing={0}
              sx={{
                width: "100%",
                border: "1px solid #000",
                borderRadius: 1,
                overflow: "hidden",
              }}
            >
              <Grid item xs={6}>
                <Tab
                  style={{ width: "100%" }}
                  label={
                    <Box display="flex" alignItems="center">
                      <img
                        src={getmycollege}
                        alt="getmycollegelogo"
                        style={{ width: 30, height: 30, marginRight: 8 }}
                      />
                      <Typography
                        variant="body2"
                        sx={{
                          textTransform: "none",
                          fontWeight: "bold",
                          fontSize: 13,
                        }}
                      >
                        GetMyCollege
                      </Typography>
                    </Box>
                  }
                  sx={{
                    backgroundColor:
                      selectedTab === 0 ? "darkmagenta" : "none",
                    color: selectedTab === 0 ? "white" : "black",
                    padding: 1.5,
                    textAlign: "center",
                    border: "0px solid black",
                    borderRadius: 0,
                    cursor: "pointer",
                    width: "100%",
                  }}
                  onClick={() => {
                    setSelectedTab(0);
                    handleTabClick("getmycolleges");
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Tab
                  style={{ width: "100%" }}
                  label={
                    <Box display="flex" alignItems="center">
                      <img
                        src={getmyproperty}
                        alt="getmypropertylogo"
                        style={{ width: 30, height: 30, marginRight: 8 }}
                      />
                      <Typography
                        variant="body2"
                        sx={{
                          textTransform: "none",
                          fontWeight: "bold",
                          fontSize: 13,
                        }}
                      >
                        GetMyProperty
                      </Typography>
                    </Box>
                  }
                  sx={{
                    backgroundColor:
                      selectedTab === 1 ? "darkmagenta" : "none",
                    color: selectedTab === 1 ? "white" : "black",
                    padding: 1.5,
                    textAlign: "center",
                    border: "0px solid black",
                    borderRadius: 0,
                    cursor: "pointer",
                    width: "100%",
                    borderLeft: "1px solid black",
                  }}
                  onClick={() => {
                    setSelectedTab(1);
                    handleTabClick("amruthaeducation");
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Tab
                  style={{ width: "100%" }}
                  label={
                    <Box display="flex" alignItems="center">
                      <img
                        src={akashlogo}
                        alt="Akashlogo"
                        style={{ width: 30, height: 30, marginRight: 8 }}
                      />
                      <Typography
                        variant="body2"
                        sx={{
                          textTransform: "none",
                          fontWeight: "bold",
                          fontSize: 13,
                        }}
                      >
                        Akash
                      </Typography>
                    </Box>
                  }
                  sx={{
                    backgroundColor:
                      selectedTab === 2 ? "darkmagenta" : "none",
                    color: selectedTab === 2 ? "white" : "black",
                    padding: 1.5,
                    textAlign: "center",
                    border: "0px solid black",
                    borderRadius: 0,
                    cursor: "pointer",
                    width: "100%",
                    borderTop: "1px solid black",
                    // borderRight: "1px solid black",
                  }}
                  onClick={() => {
                    setSelectedTab(2);
                    handleTabClick("akash");
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Tab
                  style={{ width: "100%" }}
                  label={
                    <Box display="flex" alignItems="center">
                      <img
                        src={Podarlogo}
                        alt="Podarlogo"
                        style={{ width: 30, height: 30, marginRight: 8 }}
                      />
                      <Typography
                        variant="body2"
                        sx={{
                          textTransform: "none",
                          fontWeight: "bold",
                          fontSize: 13,
                        }}
                      >
                        Podar
                      </Typography>
                    </Box>
                  }
                  sx={{
                    backgroundColor:
                      selectedTab === 3 ? "darkmagenta" : "none",
                    color: selectedTab === 3 ? "white" : "black",
                    padding: 1.5,
                    textAlign: "center",
                    border: "0px solid black",
                    borderRadius: 0,
                    cursor: "pointer",
                    width: "100%",
                    borderTop: "1px solid black",
                    borderLeft: "1px solid black",
                  }}
                  onClick={() => {
                    setSelectedTab(3);
                    handleTabClick("podar");
                  }}
                />
              </Grid>
            </Grid>
          </Box>

          <Button
            variant="contained"
            fullWidth
            onClick={handleLogin}
            disabled={loading}
            sx={{
              marginBottom: 2,
              mt: 2,
              backgroundColor: "#233098",
              fontWeight: "bold",
            }}
            startIcon={<SendIcon />}
          >
            Submit
          </Button>

          <Typography textAlign={"center"} sx={{ fontWeight: "bold" }}>
            Copyright © 2024 - SN Info Systems
          </Typography>
        </Grid>

        <Snackbar
          open={openSnackbar}
          autoHideDuration={2000}
          onClose={handleSnackbarClose}
        >
          <Alert
            severity={snackbarSeverity}
            onClose={handleSnackbarClose}
            variant="filled"
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Grid>
    </Grid>
  );
};

export default Login;

